import styled from '@emotion/styled';
import React, { useState } from 'react';
import ButtonTheme from '../components/common/Button';
import ProfileForm from '../components/form/ProfileForm';
import PasswordForm from '../components/form/PasswordForm';
import Modal from '../components/common/Modal';

const ProfileContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  gap: 2rem;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  h1 {
    margin: -0.75rem 0 1rem;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
`;

const BottomSection = styled.div`
  width: 50%;
`;

const Seperator = styled.hr`
  margin: 2rem 0;
  height: 2px;
  background-color: #0c0c0c;
  border: none;
`;

const SideBar = styled.div`
  width: 30%;
  background-color: #0c0c0c;
  color: #fff;
  border-radius: 5px;
  height: 420px;
  ul {
    list-style: none;
    margin: 0;
    padding: 2rem 0;
    li {
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      .vertical-line {
        height: 20px;
        border-left: 3px solid #02a3fb;
        border-radius: 5px;
        margin-right: 1rem;
      }
      .display-none {
        visibility: hidden;
      }  
    }
  }
`;

const ModalContainer = styled.div`
  color: #fff;
  p {
    margin: 0.5rem 0 2rem;
  }
  .flex-between {
    display: flex;
    justify-content: space-evenly;
  }
`;

const ProfilePage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Profile');
  const [modalType, setModalType] = useState('');

  const handleModalClose = () => setModalType('');

  const handleModalAction = () => {
    debugger
    if (modalType === 'delete') {
      console.log('Account deleted');
    } else if (modalType === 'logout') {
      console.log('Logged out');
    }
    sessionStorage.clear();
window.location.href = '/';
    setModalType('');
  };

  return (
    <>
      <Modal isOpen={Boolean(modalType)} onClose={handleModalClose}>
        <ModalContainer>
          <p>{modalType === 'delete' ? 'Are you sure you want to delete your account?' : 'Are you sure you want to log out?'}</p>
          <div className="flex-between">
            <ButtonTheme onClick={handleModalClose} background="#fff" color="#0C0C0C" border='none'>
              Cancel
            </ButtonTheme>
            <ButtonTheme onClick={handleModalAction} background="Red" border='none'>
              {modalType === 'delete' ? 'Delete' : 'Log Out'}
            </ButtonTheme>
          </div>
        </ModalContainer>
      </Modal>

      <ProfileContainer>
        <SideBar>
          <ul>
            {['Profile', 'Password'].map((tab) => (
              <li key={tab} onClick={() => setActiveTab(tab)}>
                <div className={`vertical-line ${activeTab === tab ? '' : 'display-none'}`} />
                {tab}
              </li>
            ))}
          </ul>
        </SideBar>

        <FormContainer>
          <h1>{activeTab}</h1>
          {activeTab === 'Profile' ? <ProfileForm /> : <PasswordForm />}

          {activeTab === 'Profile' && (
            <BottomSection>
              <Seperator />
              <div className="flex-between">
                <ButtonTheme background="Red" padding="0.5rem 2rem" primary onClick={() => setModalType('delete')}>
                  Delete Account
                </ButtonTheme>
                <ButtonTheme background="#0C0C0C" padding="0.5rem 3rem" primary onClick={() => setModalType('logout')}>
                  Log Out
                </ButtonTheme>
              </div>
            </BottomSection>
          )}
        </FormContainer>
      </ProfileContainer>
    </>
  );
};

export default ProfilePage;
